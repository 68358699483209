@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-hidden::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  .scrollbar-hidden {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
  }
  